/* eslint-disable */

<template>
  <div id="Lgx315088047513272320">
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-4">
            <h3 class="font-bold mb-4">Snippets</h3>
            <vue-draggable-container id="wordBank" class="list wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer command-block">POST</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">Accept</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">Cisco-IOS-XE</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">interface/GigabitEthernet/1/</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">GET</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">PUT</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="vx-row pl-4 pr-4">
              <div class="w-2/3 p-8">
                <div class="flex items-center mb-base">
                  <h3 class="font-bold">URL – http://10.10.10.10/restconf/api/running/native/</h3>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg ml-4"
                    id="input0"
                    style="width: 10vw"
                  ></vue-draggable-container>
                </div>

                <div class="flex items-center mb-base">
                  <h3 class="font-bold">HTTP Verb -</h3>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg ml-4"
                    id="input1"
                    style="width: 10vw"
                  ></vue-draggable-container>
                </div>

                <div class="flex items-center mb-base">
                  <h3 class="font-bold">Body - N/A</h3>
                </div>

                <div class="flex items-center mb-base">
                  <h3 class="font-bold">Headers-</h3>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg ml-4 mr-4"
                    id="input2"
                    style="width: 10vw"
                  ></vue-draggable-container>
                  <h3 class="font-bold">-application/vnd.yang.data+json</h3>
                </div>

                <div class="flex items-center mb-base">
                  <h3 class="font-bold">Authentication-privileged level 15 credentials</h3>
                </div>
              </div>

              <div class="w-1/3">
                <div class="p-4 text-success rounded" style="backdrop-filter: blur(1000px); background-color: rgb(0 0 0 / 44%)">
                  <div class="nmap-command-prompt">
                    {<br />
                    &nbsp;&nbsp;"Cisco-IOS-XE-native:GigabitEthernet":&nbsp;{<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;"name":&nbsp;"1",<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;"vrf":&nbsp;{<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"forwarding":&nbsp;"MANAGEMENT"<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;},<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;"ip":&nbsp;{<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"address":&nbsp;{<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"primary":&nbsp;{<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"address":&nbsp;"10.0.0.151",<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"mask":&nbsp;"255.255.255.0"<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;},<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;"mop":&nbsp;{<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"enabled":&nbsp;false<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;},<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;"Cisco-IOS-XE-ethernet:negotiation":&nbsp;{<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"auto":&nbsp;true<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;}<br />
                    &nbsp;&nbsp;}<br />
                    }<br />
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard-command';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'interface/GigabitEthernet/1/') {
        totalScore++;
        document.getElementById('input0').style.borderColor = '#77FAAE';
        document.getElementById('input0').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input1').textContent === 'GET') {
        totalScore++;
        document.getElementById('input1').style.borderColor = '#77FAAE';
        document.getElementById('input1').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input2').textContent === 'Accept') {
        totalScore++;
        document.getElementById('input2').style.borderColor = '#77FAAE';
        document.getElementById('input2').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#Lgx315088047513272320 {
  .answerStandard {
    min-height: 55px;
  }
}

.currently-dragging-standard-command {
  background-color: #0042ff !important;
  padding: 12px;
  color: white;
  z-index: 99;
  min-width: auto;
}

.command-block {
  display: inline-flex;
  max-height: 100px;
  margin: 5px;
  padding: 10px;
  text-align: left;
  color: #fff;
  border: 1px solid #3f3f3f;
  background-color: #fd4445;
  border-radius: 5px;
}
</style>
